import React from 'react'
import styled from '@emotion/styled'
import DefaultLayout from '../components/layouts/default'
import Core from '../components/cybersecurity/core'
import bp from '../components/common/breakpoints'
import About from '../components/cybersecurity/about'

const CybersecurityContainer = styled.div`
  width: 100vw;
  height: 100vh;
  padding-bottom: 8rem;
  margin-bottom: 8rem;
  box-sizing: border-box;

  ${bp[2]} {
    margin-bottom: unset;
  }
`

const Cybersecurity = () => {
  return (
    <div>
      <div>
        <About />
      </div>
      <CybersecurityContainer>
        <Core />
      </CybersecurityContainer>
      <DefaultLayout withContactPadding />
    </div>
  )
}

export default Cybersecurity
