import React, { useState } from 'react'
import styled from '@emotion/styled'
import { motion, AnimatePresence } from 'framer-motion'
import bp from '../common/breakpoints'
import { PRIMARY_COLOR, WHITE_COLOR } from '../common/color'

const topics = [
  {
    title: 'Infrastructure Security',
    list: [
      'Metrics definition for Cybersecurity',
      'Threat identification',
      'Governance and Legal Considerations',
    ],
  },
  {
    title: 'Data Security',
    list: [
      'Firewall',
      'VPN',
      'IDS ',
      'LAN Segmentation & Other Protective Tech.',
    ],
  },
  {
    title: 'Cybersecurity Risk Mitigation Plan',
    list: [
      'Training',
      'Business Impact Analysis',
      'Strategy definition for Cybersecurity at your Company',
    ],
  },
  {
    title: 'Cybersecurity awareness',
    list: ['Encryption', 'Back Up', 'Identity Access Management', 'DLP'],
  },
]

const buttonPlanetVariantsCommon = {
  initial: {
    x: '-50%',
    y: '-50%',
  },
  scale: {
    scale: [0.9, 1.1, 1.2, 1.0, 0.9],
    x: '-50%',
    y: '-50%',
    transition: {
      duration: 2,
      delay: 1,
      loop: Infinity,
      ease: 'linear',
    },
  },
}

const buttonPlanetVariantsOdd = {
  initial: (custom) => ({
    rotate: 200 / custom,
    y: '50%',
    x: '50%',
  }),
  origin: {
    rotate: 0,
  },
}

const buttonPlanetVariantsEven = {
  initial: (custom) => ({
    rotate: (200 / custom) * -1,
    x: '50%',
    y: '50%',
  }),
  origin: {
    rotate: 0,
  },
}

const Orbit = styled(motion.div)`
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1px dashed rgba(245, 252, 255, 0.3);
  border-radius: 50%;
  transform: translate(50%, 50%);

  width: ${(props) => `${350 * props.number + 250}px`};
  height: ${(props) => `${350 * props.number + 250}px`};

  ${bp[0]} {
    width: ${(props) => `${250 * props.number + 250}px`};
    height: ${(props) => `${250 * props.number + 250}px`};
  }
`

const OrbitContent = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  ${bp[3]} {
    top: 50%;
    left: 0;
  }
`

const Planet = styled(motion.button)`
  appearance: none;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-color: transparent;
  text-align: start;
  cursor: pointer;

  &:focus {
    outline: transparent;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background-color: ${PRIMARY_COLOR};
    border-radius: 50%;
  }
`

const PlanetTitle = styled(motion.h4)`
  position: absolute;
  margin: 0;
  width: 8rem;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 0.75rem;
  text-transform: uppercase;
  left: ${(props) => (props.current === 0 ? '6rem' : 'unset')};
  right: ${(props) => (props.current === 0 ? 'unset' : '-2rem')};

  ${bp[3]} {
    left: 50%;
    transform: translateX(-50%);
    bottom: ${(props) => (props.current === 0 ? '2.5rem' : 'unset')};
    top: ${(props) => (props.current === 0 ? 'unset' : '2.5rem')};
  }
`

const PlanetList = styled(motion.ul)`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: absolute;
  left: 0;
  width: 190px;
  margin: 0;
  padding: 1rem;
  background-color: #0f1215;
  border: 1px solid rgba(245, 252, 255, 0.3);
  list-style-type: none;
  box-sizing: border-box;
  overflow: hidden;

  & > li {
    margin: 0;
  }
`

const PlanetListAbove = styled(PlanetList)`
  top: 2rem;
  left: -2rem;

  ${bp[0]} {
    top: 2rem;
    left: 2rem;
  }

  ${bp[1]} {
    top: 0;
    left: 10.5rem;
    transform: translateY(-50%);
  }

  ${bp[3]} {
    transform: translate(-40%, 0);
    left: unset;
    top: unset;
    bottom: 6rem;
  }
`
const PlanetListBelow = styled(PlanetList)`
  top: 1.5rem;
  left: -9.5rem;

  ${bp[0]} {
    top: 1.5rem;
    left: -13.5rem;
  }

  ${bp[1]} {
    top: 0;
    left: -22rem;
    transform: translateY(-50%);
  }

  ${bp[3]} {
    transform: translate(-40%, 0);
    left: unset;
    top: 5rem;
  }
`

const Topic = ({ index, title, list }) => {
  const [showList, setShowList] = useState(false)

  const handleClick = () => {
    setShowList((prevstate) => !prevstate)
  }

  return (
    <Orbit
      custom={index + 1}
      initial="initial"
      animate={'origin'}
      transition={{ duration: 2 }}
      variants={
        index % 2 === 0 ? buttonPlanetVariantsEven : buttonPlanetVariantsOdd
      }
      number={index + 1}
    >
      <OrbitContent>
        <Planet
          initial="initial"
          animate={'scale'}
          transition={{ duration: 2 }}
          variants={buttonPlanetVariantsCommon}
          type="button"
          onClick={handleClick}
        />
        <PlanetTitle
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2, duration: 2 }}
          current={index % 2}
        >
          {title}
        </PlanetTitle>
        <AnimatePresence>
          {index % 2 === 0 ? (
            <PlanetListAbove
              initial={{
                height: 0,
              }}
              animate={
                showList
                  ? { height: 'auto', display: 'block' }
                  : { height: 0, display: 'none' }
              }
              exit={{ height: 0 }}
            >
              {list.map((item, index) => (
                <li key={index}>- {item}</li>
              ))}
            </PlanetListAbove>
          ) : (
            <PlanetListBelow
              initial={{
                height: 0,
              }}
              animate={
                showList
                  ? { height: 'auto', display: 'block' }
                  : { height: 0, display: 'none' }
              }
              exit={{ height: 0 }}
            >
              {list.map((item, index) => (
                <li key={index}>- {item}</li>
              ))}
            </PlanetListBelow>
          )}
        </AnimatePresence>
      </OrbitContent>
    </Orbit>
  )
}

const Container = styled.div`
  height: 100vh;
  min-height: 820px;
  color: ${WHITE_COLOR};

  ${bp[3]} {
    width: 100%;
    height: 100%;
  }
`

const OrbitContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;

  ${bp[3]} {
    left: unset;
    right: 0;
    bottom: 50%;
  }
`

const CoreContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 250px;
  height: 250px;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  background: radial-gradient(
    53.56% 53.56% at 49.97% 53.71%,
    #ffffff 0%,
    #0f1215 100%
  );
  border: 10px solid $yellowColor;
  box-sizing: content-box;

  ${bp[3]} {
    right: 0;
    left: unset;
    bottom: 50%;
    transform: translate(50%, 50%);
  }
`

const CoreContent = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`

const CoreTitle = styled.h4`
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0;

  ${bp[3]} {
    margin-top: 0.5rem;
    position: relative;
    transform: unset;
    left: unset;
    top: unset;
  }
`

const OrbitLine = styled.span`
  position: absolute;
  width: 1px;
  height: 690px;
  bottom: 10rem;
  background-color: rgba(255, 255, 255, 0.6);

  ${bp[0]} {
    height: 560px;
  }

  ${bp[3]} {
    width: 600px;
    height: 1px;
    right: 10rem;
    bottom: unset;
  }

  &::after {
    content: '\\2039';
    position: absolute;
    top: -5px;
    left: -3.5px;
    transform: rotate(90deg);
    font-weight: 100;
    font-size: 3rem;
    line-height: 0;
    color: rgba(255, 255, 255, 0.6);

    ${bp[3]} {
      transform: unset;
      left: -12px;
    }
  }
`

const Core = () => {
  return (
    <Container>
      <OrbitContainer>
        <CoreContainer>
          <CoreContent>
            <CoreTitle>C.O.R.E.</CoreTitle>
          </CoreContent>
        </CoreContainer>
        <OrbitLine />
        {topics
          .map((topic, index) => (
            <Topic
              key={`topic-${index}`}
              index={index}
              title={topic.title}
              list={topic.list}
            />
          ))
          .reverse()}
      </OrbitContainer>
    </Container>
  )
}

export default Core
