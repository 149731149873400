import React from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import { BACKGROUND_COLOR, PRIMARY_COLOR, WHITE_COLOR } from '../common/color'
import bp from '../common/breakpoints'

const variantsBox = {
  initial: {
    '--cybersecurity-box-height': '0%',
  },
  animate: {
    '--cybersecurity-box-height': '100%',
  },
}

const variantsHeader = {
  initial: {
    x: -100,
    opacity: 0,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
}

const variantsBoxItem = {
  initial: {
    opacity: 0,
    scale: 0,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  borderInitial: {
    '--cybersecurity-box-button-border': '0%',
  },
  borderAnimate: {
    '--cybersecurity-box-button-border': '100%',
    transition: {
      when: 'afterChildren',
      delay: 5,
      duration: 2,
    },
  },
}

const Container = styled.div`
  position: relative;
  max-width: 540px;
  width: 100%;
  align-self: center;
  padding: 3rem 1rem;
  box-sizing: border-box;
  color: ${WHITE_COLOR};

  ${bp[3]} {
    position: absolute;
    left: 25%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const Title = styled(motion.h1)`
  position: relative;
  line-height: 1.2;
  font-size: 2.2rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1.5rem;

  &:before {
    content: '';
    position: absolute;
    top: -1rem;
    width: 3rem;
    height: 2px;
    background-color: ${PRIMARY_COLOR};
  }
`

const Paragraph = styled(motion.p)`
  margin: ${(props) => (props.withMargin ? '1.5rem 0' : '0')};
`

const Box = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 360px;
  max-height: 380px;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border: 1px dashed rgba(245, 252, 255, 0.3);
  box-sizing: border-box;
  background-color: #10151d;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% - 2rem);
    height: calc(var(--cybersecurity-box-height) - 2rem);
    background-color: ${BACKGROUND_COLOR};
    transform-origin: center;
    left: 0;
    top: 0%;
    transform: translate(1rem, 1rem);
    z-index: 0;
  }

  ${bp[3]} {
    &::before {
      width: calc(100% - 3rem);
      height: calc(var(--cybersecurity-box-height) - 3rem);
      transform: translate(1.5rem, 1.5rem);
    }
  }
`

const BoxTitle = styled(motion.h2)`
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  line-height: 1.2;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
  color: ${PRIMARY_COLOR};
`

const BoxSubTitle = styled(motion.h3)`
  position: relative;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.6rem;
  letter-spacing: 0;
  word-spacing: 0;
  white-space: nowrap;
  z-index: 1;
`

const BoxButtonContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 1.5rem;

  &::before {
    position: absolute;
    content: '';
    bottom: 6.5rem;
    left: 0;
    width: var(--cybersecurity-box-button-border);
    border-top: 1px dashed rgba(245, 252, 255, 0.3);
  }
`

const BoxButtonLink = styled(Link)`
  position: relative;
  padding: 0.5rem 2rem;
  color: ${WHITE_COLOR};
  border: 1px solid gray;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
`

const About = () => {
  return (
    <Container>
      <Title
        initial="initial"
        animate="animate"
        variants={variantsHeader}
        transition={{ duration: 2 }}
      >
        Beware the Cyber Threat
      </Title>
      <Paragraph
        initial="initial"
        animate="animate"
        variants={variantsHeader}
        transition={{ duration: 2, delay: 1 }}
      >
        From infiltrations on infrastructure and data breaches to spear phishing
        and brute force. Online threats are varied and they don’t discriminate
        organizations from individuals when looking for a target.
      </Paragraph>
      <Paragraph
        initial="initial"
        animate="animate"
        variants={variantsHeader}
        transition={{ duration: 2, delay: 2 }}
        withMargin
      >
        ...Let us be part of your Risk Mitigation Strategy
      </Paragraph>

      <Box
        initial="initial"
        animate="animate"
        variants={variantsBox}
        transition={{
          duration: 2,
        }}
      >
        <BoxSubTitle
          initial="initial"
          animate="animate"
          variants={variantsBoxItem}
          transition={{ duration: 2, delay: 1 }}
        >
          Alio It
        </BoxSubTitle>
        <BoxTitle
          initial="initial"
          animate="animate"
          variants={variantsBoxItem}
          transition={{ duration: 2, delay: 2 }}
        >
          Cybersecurity Operation Risk Evasion
        </BoxTitle>
        <BoxButtonContainer
          initial={['initial', 'borderInitial']}
          animate={['animate', 'borderAnimate']}
          variants={variantsBoxItem}
          transition={{ duration: 2, delay: 3 }}
        >
          <BoxButtonLink to="#contact">Contact Us</BoxButtonLink>
        </BoxButtonContainer>
      </Box>
    </Container>
  )
}

export default About
